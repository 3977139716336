import React from "react";
import PageLayout from "../components/pagelayout";
import SEO from "../components/seo";
import Image from "../components/image";

const Career = () => (
  <PageLayout>
    <SEO title="Career" />
    <h2 className="career-title">About</h2>
    <div id="career-photo-wrapper">
      <Image name="smallerphoto" />
    </div>
    <p className="paragraph">
      Stefano is a world-class Product Manager and Entrepreneur with experience in Italy,
      the United Kingdom and both coasts of the United States.
      <br /> He holds an MBA with Honors from a World's Top 50 Business School,
      but he loves to go beyond the strategic level and delve into product
      requirements and user stories, prototyping and iterative development,
      product testing and user trials.
    </p>
    <p className="paragraph">
      Stefano is also a Web & App Developer focused on Node.js, React /
      Native, and Flutter. <br />
      He is self-taught since the early years of the Web. More recently, he
      completed the Full-Stack Web Development Bootcamp at the University of
      Pennsylvania.
    </p>
    <p className="paragraph">
      As a practitioner and advocate of discovery-driven methodologies such as
      Agile Development and The Lean Startup, Stefano thrives in entrepreneurial
      environments of any size. He has shown to be equally comfortable:
    </p>
    <p className="bullet">
      » leading and coaching a team of Product Managers at a Fortune 50 company,
      where as a Director he successfully introduced a discovery-driven process
      to improve product-market fit;
    </p>
    <p className="bullet">
      » being a Solo-Entrepreneur to start an e-commerce business and develop
      IoT solutions, leveraging his own skills as well as a global team of
      freelancers;
    </p>
    <p className="bullet">
      » designing and developing Web and Mobile Apps to solve problems through
      user-driven experiences, as a freelancer and as part of development teams.
    </p>
    <br />
    <h2 className="career-title">Experience</h2>
    <div className="career-logos">
      <Image name="iot" />
      <Image name="comcast" />
      <Image name="mediamelon" />
      <Image name="vfgroup" />
      <Image name="vfitaly" />
      <Image name="lbi" />
    </div>
    <br />
    <h2 className="career-title">Studies</h2>
    <div className="career-logos">
      <Image name="penn" />
      <Image name="hult" />
      <Image name="ateneo" />
      <Image name="federico" />
    </div>
    <br />
    <h2 className="career-title">More Details</h2>
    <div className="career-logos">
      <a
        href="https://www.linkedin.com/in/stefanopicker/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image name="linkedin-button" />
      </a>
    </div>
    <br />
  </PageLayout>
);

export default Career;
